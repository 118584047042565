import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView w="100%" textCenter m="0 auto" mb="-5px">
          <CFImage
            w="100%"
            src={mobileAbout}
            alt="Miraku Sushi hero text"
            zIndex={98}
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView m="0 auto" w="100%" maxWidth="1400px">
          <CFImage
            w="100%"
            src={about}
            alt="Miraku Sushi hero text"
            zIndex={98}
          />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
